import "./App.css";
import mattyPong from "../src/content/matty_pong.gif";

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <img src={mattyPong} alt="Matty Pong"/>
                <div className='overflow' id="result" style={{fontSize: '12' +
                        'px'}}>
                    <text>📸 @landon_thacker</text>
                </div>
            </header>
        </div>
    );
}

export default App;
